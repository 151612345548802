import React, { useRef } from "react"
import "flickity/css/flickity.css"
import styled from "@emotion/styled"
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null

const flickityOptions = {
  freeScroll: false,
  pageDots: true,
  wrapAround: true,
  prevNextButtons: true,
}

const TvAdsCarousel = ({ data, sliderLinkText, page }) => {
  var prevNext = useRef()
  const imageCounter = data => {
    let slider = []
    for (let i = 0; i < data.length; i = i + 2) {
      slider.push(
        <Row key={i.toString()} page={page}>
          {data[i] && (
            <Card page={page}>
              {data[i].image && (
                <Image
                  className="lazyload"
                  data-src={`${data[i].image}?key=small-card`}
                  alt=""
                />
              )}
              <TextBox>
                {data[i].text && (
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: data[i].text,
                    }}
                  />
                )}
                {data[i].link && sliderLinkText && (
                  <CardLink
                    href={data[i].link}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {sliderLinkText}
                  </CardLink>
                )}
              </TextBox>
            </Card>
          )}
          {data[i + 1] && (
            <Card page={page}>
              {data[i + 1].image && (
                <Image
                  className="lazyload"
                  data-src={`${data[i + 1].image}?key=small-card`}
                  alt=""
                />
              )}
              <TextBox>
                {data[i + 1].text && (
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: data[i + 1].text,
                    }}
                  />
                )}
                {data[i + 1].link && sliderLinkText && (
                  <CardLink href={data[i + 1].link} target="_blank">
                    {sliderLinkText}
                  </CardLink>
                )}
              </TextBox>
            </Card>
          )}
        </Row>
      )
    }
    return slider
  }
  return typeof window !== "undefined" ? (
    <Carousel page={page}>
      <div style={{ width: "100%" }}>
        <Flickity
          flickityRef={c => (prevNext = c)}
          options={flickityOptions}
          className={"carousel"}
          static
          reloadOnUpdate
        >
          {imageCounter(data).map(item => item)}
        </Flickity>
      </div>
    </Carousel>
  ) : null
}

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  flex-direction: ${({ page }) => (page === "lyneup_2b" ? "row" : "column")};
  padding: ${({ page }) => (page === "lyneup_2b" ? "0px" : "0px 15px")};

  @media (max-width: 980px) {
    flex-direction: column;
    padding: 0px 15px;
  }
`

export const Card = styled.div`
  min-height: 100px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  object-fit: contain;
  background: #fff;
  box-shadow: 0px 3px 10px 1px rgba(206, 206, 206, 0.5);
  margin: ${({ page }) => (page === "lyneup_2b" ? "10px" : "10px 0px")};
  padding: 0px 15px;
  box-sizing: border-box;
  border-radius: 5px;
  background-image: url("https://static.percko.com/uploads/889dc717-d9d8-4329-916b-11e97dc19e17.png");
  background-repeat: no-repeat;
  background-position: 95% 90%;
  background-size: 50px;

  @media (max-width: 850px) {
  }

  @media (max-width: 550px) {
    padding: 0px 10px;
  }

  @media (max-width: 480px) {
    min-height: 135px;
  }

  @media (max-width: 384px) {
    min-height: 140px;
  }
`

export const Image = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0;
  max-width: 90px;
  margin-right: 20px;

  @media (max-width: 480px) {
    margin-right: 10px;
  }
`

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

export const Text = styled.div`
  font-weight: normal;
`

export const CardLink = styled.a`
  text-decoration: underline;
  color: #262626;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 60px 0px 0px;
`

export const Carousel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px;

  .flickity-prev-next-button {
    background-color: transparent;
    color: #111;
    width: 36px;
    height: 36px;
    border: 2px solid #111;
    top: auto;
    bottom: -65px;
    z-index: 2;
    right: 20%;

    @media (max-width: 550px) {
      right: 4%;
    }

    :hover {
      background-color: #111;
      color: #fff;
    }
  }

  .flickity-page-dots {
    bottom: -37px;
  }

  .flickity-prev-next-button.previous {
    left: 20%;

    @media (max-width: 550px) {
      left: 4%;
    }
  }

  .flickity-prev-next-button.next {
    right: 20%;

    @media (max-width: 550px) {
      right: 4%;
    }
  }
`

export const PrevNext = styled.div`
  margin: 0 10px;
  width: 20px;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  :hover {
    cursor: pointer;
  }
`

export const Indicators = styled.img`
  margin: 0;
`

export default TvAdsCarousel
