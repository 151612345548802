import React from "react"
import styled from "@emotion/styled"
import TvAdsCarousel from "../../TvAdsCarousel"

const TvAdsSlider = ({ data, sliderLinkText, page }) => {
  return (
    <TvAdsSliderWrapper>
      <TvAdsCarousel data={data} sliderLinkText={sliderLinkText} page={page} />
    </TvAdsSliderWrapper>
  )
}

const TvAdsSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px -15px;
  align-items: center;
  justify-content: center;
  width: calc(100% + 30px);
`

export default TvAdsSlider
